import { useEffect, useInsertionEffect } from 'react';

import { CurrencyProvider } from '~/pages-modules/portfolio/providers/currency-provider';

import { useTheme } from '~/common/providers/theme';
import { ConfigurationsProvider } from '~/common/providers/subscription';
import { SidebarProvider } from '~/common/providers/sidebar';
import { MobileHeaderProvider } from '~/common/providers/mobile-header';
import { BackdropProvider } from '~/common/providers/backdrop';
import { IFCWithChildren } from '~/common/interfaces/i-fc-with-children';
import { ModalProvider } from '~/common/hooks/use-modal';
import { useAppAuthData } from '~/common/hooks/use-app-auth-data';

import {
  BodyContainer,
  DesktopSidebarContainer,
  PrivateLayoutContainer,
} from './styles';
import { SubscriptionAlertProvider } from './providers/subscription-alert-provider';
import {
  PrivateLayoutProvider,
  usePrivateLayout,
} from './providers/private-layout-provider';
import { Sidebar } from './components/Sidebar';
import { MobileHeader } from './components/MobileHeader';
import { DiscordAuthAlertProvider } from './providers/discord-auth-alert-provider';

const PrivateLayuoutFC: IFCWithChildren = ({ children }) => {
  const { bodyRef } = usePrivateLayout();
  const { setTheme } = useTheme();
  const { register: appAuthDataRegister } = useAppAuthData();

  useEffect(() => {
    appAuthDataRegister();
  }, []);

  useInsertionEffect(() => {
    setTheme('dark');
  }, []);

  return (
    <BackdropProvider>
      <ModalProvider>
        <ConfigurationsProvider>
          <SubscriptionAlertProvider>
            <DiscordAuthAlertProvider>
              <PrivateLayoutContainer>
                <CurrencyProvider>
                  <MobileHeaderProvider>
                    <SidebarProvider>
                      <MobileHeader />
                      <DesktopSidebarContainer>
                        <Sidebar />
                      </DesktopSidebarContainer>
                    </SidebarProvider>
                    <BodyContainer ref={bodyRef}>{children}</BodyContainer>
                  </MobileHeaderProvider>
                </CurrencyProvider>
              </PrivateLayoutContainer>
            </DiscordAuthAlertProvider>
          </SubscriptionAlertProvider>
        </ConfigurationsProvider>
      </ModalProvider>
    </BackdropProvider>
  );
};

export const PrivateLayout: IFCWithChildren = ({ children }) => {
  return (
    <PrivateLayoutProvider>
      <PrivateLayuoutFC>{children}</PrivateLayuoutFC>
    </PrivateLayoutProvider>
  );
};
