import { FC } from 'react';

import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { IoCloseCircle } from 'react-icons/io5';

import { useTheme } from 'styled-components';

import { sizes } from '~/common/constants/sizes';
import { Text } from '~/common/components/Text';
import { Button } from '~/common/components/Button';

import { SubscriptionSuccessModalContainer } from './styles';
import { FaDiscord } from 'react-icons/fa';
import { ButtonTypesEnum } from '~/common/enums/button-types-enum';
import { env } from '~/common/constants/env';

interface IDiscordAuthMessageModalProps {
  readonly close: () => void;
  readonly success: boolean;
}
export const DiscordAuthMessageModal: FC<IDiscordAuthMessageModalProps> = ({
  close,
  success,
}) => {
  const theme = useTheme();

  if (!success)
    return (
      <SubscriptionSuccessModalContainer>
        <div>
          <IoCloseCircle color={theme.red} size={72} />
        </div>
  
        <Text bold size="xlg" style={{ textAlign: 'center' }}>
          Falha
        </Text>
  
        <Text size="md" bold style={{ textAlign: 'center' }}>
          Não foi possível conectar sua conta do Discord. Tente novamente.
        </Text>

        <Button
          text="Fechar"
          onClick={close}
          color="green"
          textColor="white"
          variant={ButtonTypesEnum.outlined}
          width="100%"
        />
      </SubscriptionSuccessModalContainer>
    );
    
  return (
    <SubscriptionSuccessModalContainer>
      <div>
        <IoMdCheckmarkCircle color={theme.green} size={72} />
      </div>

      <Text bold size="xlg" style={{ textAlign: 'center' }}>
        Sucesso
      </Text>

      <Text size="md" bold style={{ textAlign: 'center' }}>
        Sua conta do Discord foi conectada!
      </Text>

      <Button
        icon={FaDiscord}
        text="Acessar servidor do Discord"  
        onClick={() => {
          window.open(env.DISCORD_INVITE_URL, '_blank');
          close();
        }}
        color="blue"
        textColor="white"
        width="100%"
      />

      <Button
        text="Fechar"
        onClick={close}
        color="green"
        textColor="white"
        width="100%"
      />
    </SubscriptionSuccessModalContainer>
  );
};
