/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createContext, useCallback, useContext } from 'react';

import { IFCWithChildren } from '~/common/interfaces/i-fc-with-children';
import { useModal } from '~/common/hooks/use-modal';

import { DiscordAuthMessageModal } from '../components/DiscordAuthMessageModal';

interface IDiscordAuthAlertContextData {
  readonly fireDiscordAuthMessage: (success: boolean) => void;
}

const DiscordAuthAlertContext = createContext<IDiscordAuthAlertContextData>(
  {} as IDiscordAuthAlertContextData
);

const DiscordAuthAlertProvider: IFCWithChildren = ({ children }) => {
  const { openModal, closeModal } = useModal();

  const fireDiscordAuthMessage = useCallback((success: boolean) => {
    // remove query params from url without refresh
    if (history.pushState) {
      const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
    }

    openModal(DiscordAuthMessageModal, {
      modalProps: {
        showHeader: false,
      },
      props: {
        close: closeModal,
        success,
      },
    });
  }, [openModal, closeModal]);

  return (
    <DiscordAuthAlertContext.Provider
      value={{
        fireDiscordAuthMessage,
      }}
    >
      {children}
    </DiscordAuthAlertContext.Provider>
  );
};

const useConfigurationsDiscordAlert = () => useContext(DiscordAuthAlertContext);
export { useConfigurationsDiscordAlert, DiscordAuthAlertProvider };
