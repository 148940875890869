/* eslint-disable import/no-cycle */
import { destroyCookie, parseCookies } from 'nookies';
import axios, { AxiosInstance } from 'axios';

import { env } from '../constants/env';
import { cookies } from '../constants/cookies';

export class AuthTokenError extends Error {
  constructor() {
    super('Error with authentication token.');
  }
}

function getGoogleAnalyticsData() {
  let gaClientId = null;
  if ((window as any)?.gaGlobal?.vid) {
    gaClientId = (window as any)?.gaGlobal?.vid;
  }

	// Use your own ID here ↓
	const pattern = /_ga_YL10D93TYS=GS\d\.\d\.(.+?)(?:;|$)/;
	const match = document.cookie.match(pattern);
	const parts = match?.[1].split(".");

	if (!parts) {
		return;
	}

	return {
    ga_client_id: gaClientId,
		ga_session_id: parts.shift(),
		ga_session_number: parts.shift(),
	};
}

export function setupApi(ctx = undefined): AxiosInstance {
  const parsedCookies = parseCookies(ctx);
  const { API_URL } = env;

  const api = axios.create({
    baseURL: API_URL,
    headers: {
      ...(parsedCookies &&
        parsedCookies[cookies.AUTH_TOKEN] && {
          Authorization: `Bearer ${parsedCookies[cookies.AUTH_TOKEN]}`,
        }),
    },
  });

  api.interceptors.request.use((config) => {
    const gaData = getGoogleAnalyticsData();

    if (gaData) {
      config.headers['ga-client-id'] = gaData.ga_client_id
      config.headers['ga-session-id'] = gaData.ga_session_id;
      config.headers['ga-session-number'] = gaData.ga_session_number;
    }

    return config;
  });

  api.interceptors.response.use(
    (success) => success,
    (error) => {
      if (error.response?.status === 401) {
        if (error.response?.data?.code === 'token_expired') {
          if (typeof document !== 'undefined') {
            destroyCookie(null, cookies.AUTH_TOKEN, { path: '/' });
            destroyCookie(null, cookies.REFRESH_TOKEN, { path: '/' });
            destroyCookie(null, cookies.LOGGED_USER, { path: '/' });
            destroyCookie(null, cookies.USER_SUBSCRIPTION, { path: '/' });
          } else {
            return Promise.reject(new AuthTokenError());
          }
        }
      }

      return Promise.reject(error);
    }
  );

  return api;
}

export const api = setupApi();

export const addBearerToken = (token: string): void => {
  api.defaults.headers.Authorization = `Bearer ${token}`;
};
