import { ChangeEventHandler, Dispatch, FC, SetStateAction } from 'react';

import { Collapse } from '@mui/material';

import { ILoginRequest } from '~/pages-modules/auth/interfaces/i-login-request';

import { UserTypes } from '~/common/enums/user-types.enum';
import {
  UserSubscriptionTypes,
  UserSubscriptionTypesFormatted,
} from '~/common/enums/user-subscription-types.enum';
import { sizes } from '~/common/constants/sizes';
import { TextInput } from '~/common/components/TextInput';
import { Text } from '~/common/components/Text';
import { Skeleton } from '~/common/components/Skeleton';
import { Button } from '~/common/components/Button';
import { AlertInfo } from '~/common/components/AlertInfo';

import { CleanDataContainer } from '../styles';

import { TSubscriptionData } from '..';
import { ButtonTypesEnum } from '~/common/enums/button-types-enum';

interface ISubscriptionDataProps {
  readonly userType: UserTypes;
  readonly subscriptionData: TSubscriptionData;
  readonly loadingData: boolean;
  readonly openCleanAccountData: boolean;
  readonly cancelAccountData: Partial<ILoginRequest>;
  readonly subscription: UserSubscriptionTypes;
  readonly onSubscriptionRedirect: () => void;
  readonly handleManageSubscription: () => Promise<void>;
  readonly setOpenCleanAccountData: Dispatch<SetStateAction<boolean>>;
  readonly handleChangeCancelDataRequestData: ChangeEventHandler<HTMLInputElement>;
  readonly handleDeleteAccountData: () => Promise<void>;
}
export const SubscriptionData: FC<ISubscriptionDataProps> = ({
  userType,
  subscriptionData,
  loadingData,
  openCleanAccountData,
  cancelAccountData,
  subscription,
  setOpenCleanAccountData,
  onSubscriptionRedirect,
  handleManageSubscription,
  handleChangeCancelDataRequestData,
  handleDeleteAccountData,
}) => {
  return (
    <section>
      {userType === UserTypes.free && (
        <div>
          {loadingData ? (
            <Skeleton width="100%" height={150} />
          ) : (
            <>
              <div id="info" style={{ marginBottom: sizes.spacing.md }}>
                <Text bold size="sm">
                  Assinatura:
                </Text>
                <Text size="sm">Grátis</Text>
              </div>

              <AlertInfo
                title="Atenção, "
                info="Para alterar seu plano , modificar o meio de pagamento ou cancelar a assinatura , clique no botão abaixo."
                color="red"
              />

              {!subscriptionData.assinaturaId ? (
                <Button
                  text="Comparar Assinaturas"
                  color="blue"
                  textColor="white"
                  width="100%"
                  onClick={onSubscriptionRedirect}
                  style={{
                    marginBottom: sizes.spacing.md,
                    marginTop: sizes.spacing.md,
                  }}
                />
              ) : (
                <Button
                  text="Gerenciar Assinatura"
                  color="blue"
                  textColor="white"
                  width="100%"
                  onClick={handleManageSubscription}
                  style={{
                    marginBottom: sizes.spacing.md,
                    marginTop: sizes.spacing.md,
                  }}
                />
              )}
            </>
          )}

          {subscriptionData?.assinaturaId && subscriptionData?.assinaturaId?.includes('REVENUECAT') && (
            <Button
              text="Trocar de Plano"
              color="blue"
              textColor="white"
              width="100%"
              onClick={onSubscriptionRedirect}
              variant={ButtonTypesEnum.outlined}
              style={{
                marginBottom: sizes.spacing.md,
                marginTop: sizes.spacing.md,
              }}
            />
          )}

          <CleanDataContainer>
            <Collapse in={!openCleanAccountData}>
              <Button
                text="Apagar conta"
                color="background2"
                textColor="red"
                borderColor="red"
                onClick={() => setOpenCleanAccountData(true)}
                style={{ marginBottom: sizes.spacing.sm }}
              />
            </Collapse>

            <Collapse in={openCleanAccountData}>
              <TextInput
                name="password"
                label="Senha"
                type="password"
                placeholder="Confirme sua senha para apagar a conta"
                value={cancelAccountData.password}
                onChange={handleChangeCancelDataRequestData}
              />

              <Button
                text="Confirmar exclusão de conta"
                color="red"
                textColor="white"
                style={{ marginTop: sizes.spacing.sm }}
                onClick={handleDeleteAccountData}
              />
            </Collapse>
          </CleanDataContainer>
        </div>
      )}

      {![UserTypes.admin, UserTypes.vip, UserTypes.free].includes(userType) && (
        <div>
          {loadingData ? (
            <Skeleton width="100%" height={150} />
          ) : (
            <>
              <div id="info">
                <Text bold size="sm">
                  Assinatura:
                </Text>
                <Text size="sm">
                  {UserSubscriptionTypesFormatted[subscription]}
                </Text>
              </div>

              <div id="info">
                <Text bold size="sm">
                  Plano Contratado:
                </Text>
                <Text size="sm">Mensal</Text>
              </div>

              <div id="info">
                <Text bold size="sm">
                  Início do Periodo Atual:
                </Text>
                <Text size="sm">{subscriptionData.dataLiberacao}</Text>
              </div>

              <div id="info">
                <Text bold size="sm">
                  Fim do Período Atual:
                </Text>
                <Text size="sm">{subscriptionData.dataValidade}</Text>
              </div>

              <div id="info">
                <Text bold size="sm">
                  Status:
                </Text>
                <Text size="sm">{subscriptionData.status}</Text>
              </div>

              <AlertInfo
                title="Atenção, "
                info="Para alterar seu plano , modificar o meio de pagamento ou cancelar a assinatura , clique no botão abaixo."
                color="red"
              />

              {!subscriptionData.assinaturaId ? (
                <Button
                  text="Comparar Assinaturas"
                  color="blue"
                  textColor="white"
                  width="100%"
                  onClick={onSubscriptionRedirect}
                  style={{
                    marginBottom: sizes.spacing.md,
                    marginTop: sizes.spacing.md,
                  }}
                />
              ) : (
                <Button
                  text="Gerenciar Assinatura"
                  color="blue"
                  textColor="white"
                  width="100%"
                  onClick={handleManageSubscription}
                  style={{
                    marginBottom: sizes.spacing.md,
                    marginTop: sizes.spacing.md,
                  }}
                />
              )}

                {subscriptionData?.assinaturaId && subscriptionData?.assinaturaId?.includes('REVENUECAT') && (
                  <Button
                    text="Trocar de Plano"
                    color="blue"
                    textColor="white"
                    width="100%"
                    variant={ButtonTypesEnum.outlined}
                    onClick={onSubscriptionRedirect}
                    style={{
                      marginBottom: sizes.spacing.md,
                      marginTop: sizes.spacing.md,
                    }}
                  />
                )}
            </>
          )}
        </div>
      )}
    </section>
  );
};
