import { FC } from 'react';

import { sizes } from '~/common/constants/sizes';
import { Text } from '~/common/components/Text';
import { Button } from '~/common/components/Button';
import { FaDiscord } from 'react-icons/fa';

interface IConnectionsDataProps {
  readonly discordUsername: string;
  readonly onDiscordLinkClick: () => void;
  readonly onDiscordUnlinkClick: () => void;
  readonly onDiscordServerClick: () => void;
}
export const ConnectionsData: FC<IConnectionsDataProps> = ({
  discordUsername,
  onDiscordLinkClick,
  onDiscordUnlinkClick,
  onDiscordServerClick,
}) => {
  return (
    <section>
      <>
        <div id="info" style={{
          marginBottom: sizes.spacing.md,
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <span>
            <Text bold size="sm">
              Discord:
            </Text>
            {discordUsername ? (
              <Text size="sm"> Conectado (<b>{discordUsername}</b>)</Text>
            ) : (
              <Text size="sm"> Não conectado</Text>
            )}
          </span>
          <span style={{ display: 'flex', gap: '10px', opacity: 1 }}>
            {discordUsername && (
              <Text size="sm" style={{ opacity: 1 }}>
                <Button
                  icon={FaDiscord}
                  color="blue"
                  textColor="white"
                  width="100%"
                  onClick={onDiscordServerClick}
                  style={{
                    marginBottom: sizes.spacing.md,
                    marginTop: sizes.spacing.md,
                    padding: sizes.spacing.sm,
                  }}
                />
              </Text>
            )}
            <Text size="md" style={{ opacity: 1 }}>
              {discordUsername ? (
                <Button
                  text="Desconectar"
                  width="100%"
                  color="background2"
                  textColor="red"
                  borderColor="red"
                  onClick={onDiscordUnlinkClick}
                  style={{
                    marginBottom: sizes.spacing.md,
                    marginTop: sizes.spacing.md,
                    padding: sizes.spacing.sm,
                  }}
                />
              ) : (
                <Button
                  text="Conectar Discord"
                  color="blue"
                  textColor="white"
                  width="100%"
                  onClick={onDiscordLinkClick}
                  style={{
                    marginBottom: sizes.spacing.md,
                    marginTop: sizes.spacing.md,
                    padding: sizes.spacing.sm,
                  }}
                />
              )}
            </Text>
          </span>
        </div>
      </>
    </section>
  );
};
